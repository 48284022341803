import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-198f09e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact-title" }
const _hoisted_2 = {
  key: 0,
  class: "contact-label"
}
const _hoisted_3 = { class: "contact-icon" }
const _hoisted_4 = { class: "contact-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "contact-tag",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.onContactClick && $setup.onContactClick(...args))),
    onDblclick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.onContactDoubleClick && $setup.onContactDoubleClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($props.contact.label)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("span", null, _toDisplayString($props.contact.label), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        ($props.contact.contactType === 'LANDLINE_PHONE')
          ? (_openBlock(), _createBlock(_component_mdicon, {
              key: 0,
              name: "phone",
              title: "Landline phone",
              size: 20
            }))
          : _createCommentVNode("", true),
        ($props.contact.contactType === 'MOBILE_PHONE')
          ? (_openBlock(), _createBlock(_component_mdicon, {
              key: 1,
              name: "cellphoneBasic",
              title: "Mobile phone",
              size: 20
            }))
          : _createCommentVNode("", true),
        ($props.contact.contactType === 'WHATSAPP')
          ? (_openBlock(), _createBlock(_component_mdicon, {
              key: 2,
              name: "whatsapp",
              title: "Whatsapp",
              size: 20
            }))
          : _createCommentVNode("", true),
        ($props.contact.contactType === 'EMAIL')
          ? (_openBlock(), _createBlock(_component_mdicon, {
              key: 3,
              name: "emailOutline",
              title: "Email",
              size: 20
            }))
          : _createCommentVNode("", true),
        ($props.contact.contactType === 'EMAIL_BILLING')
          ? (_openBlock(), _createBlock(_component_mdicon, {
              key: 4,
              name: "email",
              title: "Billing email",
              size: 20
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", null, _toDisplayString($props.contact.value), 1)
    ])
  ], 32))
}