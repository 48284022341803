
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import { computed, reactive, ref } from "vue"
import PrimaryButton from "../buttons/PrimaryButton.vue"

export default {
  props: {
    contactInfo: {
      type: Object,
    },
    saving: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submit", "delete", "saving"],
  setup(props: any, { emit }: any) {
    const contactTypeOptions = reactive([
      {
        label: "Mobile phone",
        value: "MOBILE_PHONE",
        selected: props.contactInfo?.contactType === "MOBILE_PHONE",
      },
      {
        label: "Whatsapp",
        value: "WHATSAPP",
        selected: props.contactInfo?.contactType === "WHATSAPP",
      },
      {
        label: "Landline phone",
        value: "LANDLINE_PHONE",
        selected: props.contactInfo?.contactType === "LANDLINE_PHONE",
      },
      { label: "Email", value: "EMAIL", selected: props.contactInfo?.contactType === "EMAIL" },
      {
        label: "Email billing",
        value: "EMAIL_BILLING",
        selected: props.contactInfo?.contactType === "EMAIL_BILLING",
      },
    ])
    const form = reactive({
      label: props.contactInfo ? props.contactInfo.label : "",
      value: props.contactInfo ? props.contactInfo.value : "",
      contactType: computed(() => contactTypeOptions.filter((option: any) => option.selected)[0]),
    })
    const rules = {
      label: { labelRequired: helpers.withMessage("The label is required", required) },
      value: { valueRequired: helpers.withMessage("The value is required", required) },
      contactType: { typeRequired: helpers.withMessage("The contact type is required", required) },
    }
    const v$: any = useVuelidate(rules, form)
    function onSubmit() {
      v$.value.$touch()
      if (!v$.value.$invalid && !props.saving) {
        emit("saving")
        const contactInfoData = {
          relationType: "ORGANIZATION",
          label: form.label,
          value: form.value,
          contactType: form.contactType.value,
        }
        if (props.contactInfo) {
          emit("submit", contactInfoData, props.contactInfo.id)
        } else {
          emit("submit", contactInfoData, null)
        }
      }
    }
    function onContactTypeClick(contactType: any) {
      if (!contactType.selected) {
        contactTypeOptions.forEach((option: any) => (option.selected = false))
      }
      contactType.selected = !contactType.selected
    }
    function deleteContactInfo() {
      emit("delete")
    }
    return { v$, onSubmit, contactTypeOptions, onContactTypeClick, deleteContactInfo }
  },
  components: { PrimaryButton },
}
