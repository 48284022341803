
import { copyToClipboard } from "@/helpers/CopyToClipboard"
import { ref } from "vue"

export default {
  name: "ContactTag",
  props: {
    contact: Object,
  },
  emits: ["edit-contact-info", "copy-contact-value"],
  setup(props: any, { emit }: any) {
    const isDoubleClicking = ref(false)

    function onContactClick() {
      setTimeout(() => {
        if (!isDoubleClicking.value) {
          emit("edit-contact-info", props.contact)
        }
      }, 300)
    }

    function onContactDoubleClick(event: any) {
      isDoubleClicking.value = true
      copyToClipboard(props.contact.value)
      setTimeout(() => {
        isDoubleClicking.value = false
      }, 300)
    }

    return {
      onContactClick,
      onContactDoubleClick,
    }
  },
}
