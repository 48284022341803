
import { ref, defineComponent, reactive, watch, computed } from "vue"
import DataCollection from "@/components/data/DataCollection.vue"
import ResultSet from "@/models/ResultSet"
import OrganizationService from "@/services/OrganizationService"
import Button from "primevue/button"
import OrganizationContactForm from "./OrganizationContactForm.vue"
import ContactTag from "./contacts/ContactTag.vue"
import PrimaryButton from "../buttons/PrimaryButton.vue"

export default defineComponent({
  name: "OrganizationContactInfo",
  components: {
    DataCollection,
    OrganizationContactForm,
    ContactTag,
    PrimaryButton,
  },
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const service = OrganizationService.getInstance()
    const contactInfoDialog = ref(false)
    const deleteContactInfoDialog = ref(false)
    const sort = ref("")
    const contacts = reactive([])
    const contactFormSaving = ref(false)

    const selectedContactInfo = ref()

    function reload() {
      contacts.length = 0
      contactInfoDialog.value = false
      selectedContactInfo.value = null
      getContactInfo(sort.value)
    }

    watch(
      () => sort.value,
      () => {
        reload()
      }
    )

    getContactInfo(sort.value)

    function getContactInfo(sort: any) {
      if (props.organization != null) {
        service.getContactInfo(props.organization.id, 0, sort).then((result: ResultSet<any>) => {
          let filteredDeleted = []
          for (let row of result.rows) {
            if (!row.deleted) {
              filteredDeleted.push(row)
            }
          }
          result.rows.length = 0
          result.rows = filteredDeleted
          result.rows
            .sort((a: any, b: any) => {
              if (a.contactType === b.contactType) {
                return a.label.localeCompare(b.label)
              }
              return a.contactType.localeCompare(b.contactType)
            })
            .forEach(function (element) {
              contacts.push(element)
            })
        })
      }
    }

    const sortColumn = (event: any) => {
      if (event.value) {
        sort.value = event.value
      } else {
        sort.value = event.sortField + "," + (event.sortOrder == 1 ? "desc" : "asc")
      }
    }

    function addContactInfo(contactInfoData: any, contactInfoId: string) {
      const contactInfoPromise = contactInfoId
        ? service.editContactInfo(contactInfoId, contactInfoData)
        : service.addContactInfo(props.organization.id, contactInfoData)

      contactInfoPromise
        .then((res: any) => {
          reload()
        })
        .catch((err: any) => {
          console.log(err)
        })
        .finally(() => (contactFormSaving.value = false))
    }

    function deleteContactInfo() {
      if (!deleteContactInfoDialog.value) {
        deleteContactInfoDialog.value = true
        return
      }

      if (selectedContactInfo.value) {
        service.deleteContactInfo(selectedContactInfo.value.id).then((res) => {
          reload()
        })
      }
      deleteContactInfoDialog.value = false
    }
    function newContactInfo() {
      selectedContactInfo.value = null
      contactInfoDialog.value = true
    }

    function editContactInfo(contact: any) {
      selectedContactInfo.value = contact
      contactInfoDialog.value = true
    }

    const sortOptions = ref([
      { label: "updatedTime ↓", value: "updatedTime,asc" },
      { label: "updatedTime ↑", value: "updatedTime,desc" },
    ])

    let users = reactive([])
    getUsersForOrg()
    function getUsersForOrg() {
      service.getUsersForOrganization(props.organization.id).then((result) => {
        Object.assign(users, result.rows)
      })
    }

    return {
      getContactInfo,
      sortOptions,
      addContactInfo,
      contactInfoDialog,
      deleteContactInfoDialog,
      deleteContactInfo,
      newContactInfo,
      editContactInfo,
      selectedContactInfo,
      users,
      contacts,
      sortColumn,
      contactFormSaving,
    }
  },
})
